import React from "react";
import ReactDOM from "react-dom";
// import 'intro.js/themes/introjs-nazanin.css';
// import 'intro.js/themes/introjs-dark.css';
import { Provider } from "react-redux";
import { Router } from "react-router-dom";
import "./i18n";
import history from "./helpers/history";
import App from "./App";
import * as serviceWorker from "./serviceWorker";
import store from "./store";
// CoachMarks
import "intro.js/introjs.css";
import "intro.js/themes/introjs-modern.css";
import "./styles/index.scss";
// import "@reach/slider/styles.css";
import "./index.css";
import "toastr/build/toastr.min.css";

window.analytics.load(process.env.REACT_APP_SEGMENT_ID);

const app = (
  // <Sentry.ErrorBoundary fallback={"An error has occurred"}>

  <Provider store={store}>
    <Router history={history}>
      <App />
    </Router>
    <div
      id="custom-plug"
      style={{
        bottom: "20px",
        height: "1px",
        position: "fixed",
        right: "20px",
        width: "1px",
        visibility: "hidden",
      }}
    ></div>
  </Provider>
  //</Sentry.ErrorBoundary>
);

ReactDOM.render(app, document.getElementById("root"));
serviceWorker.unregister();
