import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import queryString from "query-string";
import { Loading } from "@100mslive/react-ui";
import api from "../../api";
import AuthForm from "../../components/AuthForm";
import { AppAnalytics } from "../../helpers/analytics_helper";
import { saveAndTrackLogin } from "../../helpers/backend_helper";
import history from "../../helpers/history";
import { currentUser, getUtmParams } from "../../utils";

class Activate extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      success: false,
      msg: "",
      response_email: null,
    };
  }

  componentWillMount() {
    const searchParams = queryString.parse(window.location?.search);
    const { code, email } = searchParams;

    AppAnalytics.track("verify_email.request.sent", { email: email });

    if (!email || !code) {
      this.setState({
        success: false,
        msg: "Invalid URL",
        loading: false,
      });
      return;
    }

    api
      .service("dashboard")
      .post("/verify-email", { email, code })
      .then(response => {
        if (response.status === 200) {
          AppAnalytics.track("verify_email.request.success", {
            email: response.data.email,
            user_id: response.data.user_id,
            data_account_id: response.data.customer_id,
            api_version: response.data.api_version,
            verified: true,
          });
          AppAnalytics.track("signed.up", {
            email: response.data.email,
            user_id: response.data.user_id,
            data_account_id: response.data.customer_id,
            api_version: response.data.api_version,
            verified: true,
            formid: "dashboard form signup",
            original_referrer_override: getUtmParams().referrer,
          });
          this.setState({
            success: response.data.success,
            msg: response.data.msg,
            response_email: response.data.email,
            loading: false,
          });
          saveAndTrackLogin(response);
        } else {
          AppAnalytics.track("verify_email.request.failure", {
            email: email,
            msg: JSON.stringify(response.msg),
            api_version: response.data.api_version,
          });
          this.setState({
            success: false,
            msg: "Something went wrong",
            loading: false,
          });
        }
      })
      .catch(err => {
        AppAnalytics.track("verify_email.request.failure", {
          email: email,
          msg: JSON.stringify(err),
        });
        this.setState({
          success: false,
          msg: err,
          loading: false,
        });
      });
  }

  autoLoginUser = () => {
    const authUser = currentUser();
    if (authUser && authUser.email === this.state.response_email) {
      AppAnalytics.track("continue.login.clicked", {
        email: authUser.email,
      });
      history.push("/dashboard");
      // redirectAfterLogin(window.location.search, this.props);
    } else {
      history.push("/login");
    }
  };

  render() {
    const { success, msg, loading } = this.state;
    return loading ? (
      <Loading />
    ) : (
      <AuthForm
        title={success ? "Successful activation" : "Something went wrong"}
        subtitle="Sign in to continue to 100ms."
      >
        <p className="text-center">{msg}</p>

        <div className="mt-4">
          <button
            className="btn btn-primary btn-block waves-effect waves-light"
            onClick={this.autoLoginUser}
          >
            Continue to Log In
          </button>
        </div>
      </AuthForm>
    );
  }
}

export default withRouter(Activate);
