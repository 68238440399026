import { isEmpty, pick } from "lodash";
import queryString from "query-string";
import toastr from "src/components/Common/toastr";
import { AppAnalytics } from "./analytics_helper";
import history from "./history";
import { formRegister } from "./hubspot_helper";
import { splitName } from "./utils";
import api from "../api";
import {
  GOOGLE_LOGIN_FAIL,
  GOOGLE_LOGIN_INIT,
  GOOGLE_LOGIN_SUCCESS,
} from "../store/auth/login/actionTypes";
import {
  getAPIURL,
  getLastAccessedWorkspaceID,
  getUtmParams,
  isAdmin,
  removeCurrentUser,
  saveCurrentUser,
  setLastAccessedWorkspaceID,
} from "../utils";

let hubspotFormData = [
  ["firstname", undefined],
  ["lastname", undefined],
  ["email", undefined],
  ["original_referrer_override", undefined],
  ["contact_referrer", undefined],
  ["verified", false],
  ["phone", ""],
  ["sdr_comments", ""],
  ["company", ""],
];

export const loginToWorkspace = data => {
  const lastWorkspaceID = getLastAccessedWorkspaceID(data.email);
  const cookie = data;
  cookie.workspaceID = data.app_id ? data.app_id : lastWorkspaceID;
  saveCurrentUser(cookie);
  setLastAccessedWorkspaceID(cookie.email, cookie.workspaceID);
};

const withTimeout = async function (promise, timeout, timeoutResp) {
  let timerId = null;
  const timeoutPromise = new Promise(function (resolve, reject) {
    timerId = setTimeout(() => {
      reject(timeoutResp);
    }, timeout);
  });
  const data = await Promise.race([promise, timeoutPromise]).finally(() =>
    clearTimeout(timerId)
  );
  return data;
};
const EMAIL_REGISTER_FORMID = "5472fcae-a8dc-4e70-8153-df4dc98c652c";
const GOOGLE_REGISTER_FORMID = "4a4f68a3-3fa2-4f89-b106-3c3b0f4dae09";
const GITHUB_REGISTER_FORMID = "765c0602-7813-498c-85db-e84972ea6d34";

// Register Method
const postRegister = (url, data, props, url_params) => {
  AppAnalytics.track("register.request.sent", { email: data.email });
  const { firstName, lastName } = splitName(data["first-name"]);
  const searchParams = queryString.parse(url_params);
  let params = {
    email: data.email,
    password: data.password,
    first_name: firstName,
    captcha_token: data["captcha-token"],
    currentRegion: data["currentRegion"],
  };
  if (lastName) {
    params["last_name"] = lastName;
  }
  if (searchParams.redirect_url) {
    const workspaceParam = queryString.parse(searchParams.redirect_url);
    params["resourceId"] = workspaceParam.resourceId;
    params["resourceType"] = workspaceParam.resourceType;
    params["code"] = workspaceParam.code;
  }

  return api
    .service("dashboard", { raw: true })
    .post(url, params)
    .then(async response => {
      if (response.status >= 200 || response.status <= 299) {
        if (response.data.success) {
          AppAnalytics.identify(response.data.customer_id, {
            email: response.data.email,
            verified: false,
            original_referrer_override: getUtmParams().referrer,
            data_account_id: response.data.customer_id,
          });

          // Get Unbounce data from query and pass to Hubspot
          const rawQuerys = new URLSearchParams(window.location.search);
          const queries = Object.fromEntries(rawQuerys.entries()) || {};

          hubspotFormData = [
            ["firstname", firstName],
            ["lastname", lastName],
            ["email", response.data.email],
            ["original_referrer_override", getUtmParams().referrer],
            ["contact_referrer", "register-mail"],
            ["verified", false],
            ["phone", queries?.phone || ""],
            ["sdr_comments", queries?.sdr_comments || ""],
            ["company", queries?.company || ""],
          ];
          try {
            await withTimeout(
              formRegister({
                formId: EMAIL_REGISTER_FORMID,
                array: hubspotFormData,
                pageName: window.location.pathname,
                pageURL: window.location.href,
              }),
              parseInt(process.env.DEFAULT_HUBSPOT_API_TIMEOUT) || 3000,
              { errorCode: "TIMEOUT" }
            );
          } catch (e) {
            console.error(e);
          }

          AppAnalytics.track("register.request.success", {
            email: data.email,
            user_id: response.data.user_id,
            data_account_id: response.data.customer_id,
            api_version: response.data.api_version,
          });
          localStorage.setItem("user", JSON.stringify(response.data));
          redirectAfterRegister(url_params, response.data);
        } else {
          AppAnalytics.track("register.request.failure", {
            email: data.email,
            msg: JSON.stringify(response.data.msg),
            api_version: response.data.api_version,
          });
          props.registerUserFailed(response.data.msg);
          //props.registrationError(response.data.msg);
        }
      }
    })
    .catch(e => {
      toastr.error(e.response.data.msg);
    });
};

const saveAndTrackLogin = loginResponse => {
  AppAnalytics.identify(loginResponse.data.customer_id, {
    email: loginResponse.data.email,
    first_name: loginResponse.data.first_name,
    last_name: loginResponse.data.last_name,
    user_id: loginResponse.data.user_id,
    data_account_id: loginResponse.data.customer_id,
    api_version: loginResponse.data.api_version,
    dashboard_version: process.env.REACT_APP_DASHBOARD_VERSION,
    events_protocol: process.env.REACT_APP_EVENTS_PROTOCOL,
    verified: true,
    original_referrer_override: getUtmParams().referrer,
    ...getUtmParams(),
  });
  AppAnalytics.track("login.request.success", {
    email: loginResponse.data.email,
    user_id: loginResponse.data.user_id,
    data_account_id: loginResponse.data.customer_id,
    api_version: loginResponse.api_version,
  });
  AppAnalytics.track("signed.in", {
    email: loginResponse.data.email,
    user_id: loginResponse.data.user_id,
    data_account_id: loginResponse.data.customer_id,
    api_version: loginResponse.api_version,
    verified: true,
    formid: "dashboard form login",
    original_referrer_override: getUtmParams().referrer,
  });
};

const redirectAfterLogin = url_params => {
  // check if redirect_url exist
  const searchParams = queryString.parse(url_params);
  if (!isEmpty(searchParams?.redirect_url)) {
    setTimeout(() => {
      window.location.href = searchParams.redirect_url;
    }, 100);
  } else if (!isEmpty(searchParams?.code)) {
    window.location.reload();
  } else {
    history.push("/dashboard");
  }
};

const redirectAfterRegister = (url_params, data) => {
  // check if redirect_url exist
  const searchParams = queryString.parse(url_params);
  if (data.token) {
    data.workspaceID = data.app_id;
    saveCurrentUser(data);
    setLastAccessedWorkspaceID(data.email, data.workspaceID);
  }
  if (searchParams?.redirect_url) {
    setTimeout(() => {
      window.location.href = searchParams.redirect_url;
    }, 100);
  } else {
    let redirect_url = `${window.location.origin}/welcome`;
    if (url_params) {
      redirect_url += url_params;
    }
    setTimeout(() => {
      window.location.href = redirect_url;
    }, 100);
  }
};

// Login Method
const postLogin = (url, data, props, url_params) => {
  const lastWorkspaceID = getLastAccessedWorkspaceID(data.email);
  AppAnalytics.track("login.request.sent", { email: data.email });
  var params = {
    email: data.email,
    password: data.password,
    captcha_token: data["captcha-token"],
    workspace_id: lastWorkspaceID,
  };
  return api
    .service("dashboard", { raw: true })
    .post(url, params)
    .then(response => {
      if (
        response.status === 400 ||
        response.status === 500 ||
        !response.data.success
      ) {
        AppAnalytics.track("login.request.failure", {
          email: data.email,
          msg: JSON.stringify(response.data.msg),
          api_version: response.data.api_version,
        });
        props.apiError(response.data.msg);
        return;
      }

      saveAndTrackLogin(response);
      loginToWorkspace(response.data);
      redirectAfterLogin(url_params, props);

      return response.data;
    })
    .catch(err => {
      AppAnalytics.track("login.request.failure", {
        email: data.email,
        msg: JSON.stringify(err),
      });
      toastr.error(err.response.data.msg);
    });
};

// postForgetPwd
const postForgetPwd = (url, data) => {
  return api
    .service("dashboard", { raw: true })
    .post(url, data)
    .then(response => {
      if (response.status === 400 || response.status === 500) {
        throw response.data;
      }
      AppAnalytics.track("forget_password_email.success", {
        email: data.email,
      });
      return response.data;
    })
    .catch(err => {
      AppAnalytics.track("forget_password_email.failure", {
        email: data.email,
      });
      throw err[1];
    });
};

const postResendEmail = ({ email }) => {
  return api
    .service("dashboard", { raw: true })
    .post("/resend-email", {
      email,
    })
    .then(response => {
      if (response.status === 400 || response.status === 500) {
        throw response.data;
      }
      AppAnalytics.track("activation.email.resend.success", {
        email,
      });
      return response.data;
    })
    .catch(err => {
      AppAnalytics.track("activation.email.resend.failure", {
        email,
      });
      throw err[1];
    });
};

const verifyGoogleAuth = (
  { id_token, email, first_name, last_name, currentRegion },
  { apiError }
) => {
  return dispatch => {
    dispatch({ type: GOOGLE_LOGIN_INIT });
    const lastWorkspaceID = getLastAccessedWorkspaceID(email);
    AppAnalytics.track("login.oauth.request.sent", { id_token, email });

    return api
      .service("dashboard", { raw: true })
      .post("/oauth/login", {
        id_token: id_token,
        workspace_id: lastWorkspaceID,
        currentRegion: currentRegion,
      })
      .then(
        // eslint-disable-next-line complexity
        async response => {
          dispatch({ type: GOOGLE_LOGIN_SUCCESS });
          if (
            response.status === 400 ||
            response.status === 500 ||
            !response.data.success
          ) {
            AppAnalytics.track("login.oauth.request.failure", {
              id_token,
              email,
              msg: JSON.stringify(response.data.msg),
              api_version: response.data.api_version,
            });
            apiError(response.data.msg);
            return;
          }
          AppAnalytics.identify(response.data.customer_id, {
            email: response.data.email,
            first_name,
            last_name,
            user_id: response.data.user_id,
            data_account_id: response.data.customer_id,
            api_version: response.data.api_version,
            dashboard_version: process.env.REACT_APP_DASHBOARD_VERSION,
            events_protocol: process.env.REACT_APP_EVENTS_PROTOCOL,
            verified: true,
            original_referrer_override: getUtmParams().referrer,
            ...getUtmParams(),
          });
          AppAnalytics.track("login.oauth.request.success", {
            email: response.data.email,
            user_id: response.data.user_id,
            data_account_id: response.data.customer_id,
            api_version: response.api_version,
          });
          if (response.data.registered) {
            AppAnalytics.track("signed.up", {
              email: response.data.email,
              user_id: response.data.user_id,
              data_account_id: response.data.customer_id,
              api_version: response.api_version,
              verified: true,
              formid: "dashboard google signup",
              original_referrer_override: getUtmParams().referrer,
            });
            window.dataLayer.push({
              event: "signed.up",
              email: `${response.data.email}`,
            });
            hubspotFormData = [
              ["firstname", first_name],
              ["lastname", last_name],
              ["email", response.data.email],
              ["original_referrer_override", getUtmParams().referrer],
              ["contact_referrer", "register-google"],
              ["verified", true],
              ["phone", ""],
              ["sdr_comments", ""],
              ["company", ""],
            ];

            let pageURL = `${window.location.origin}${window.location.pathname}`;

            // Prefix UTM queries manually if exists
            const utmParamsKeys = [
              "utm_source",
              "utm_medium",
              "utm_campaign",
              "utm_keyword",
              "utm_term",
              "utm_id",
              "referrer",
              "ref",
              "gclid",
            ];
            try {
              const hashParams = queryString.parse(window.location.hash);
              // get query passed from google SSO
              const queries = JSON.parse(
                decodeURIComponent(hashParams?.state || "{}")
              );
              const filteredUTMParams = pick(queries, utmParamsKeys);

              // Get Unbounce data from Google SSO and pass to Hubspot
              hubspotFormData.push(["phone", queries?.phone || ""]);
              hubspotFormData.push([
                "sdr_comments",
                queries?.sdr_comments || "",
              ]);
              hubspotFormData.push(["company", queries?.company || ""]);

              if (!isEmpty(filteredUTMParams)) {
                const utmQueryString = new URLSearchParams(
                  filteredUTMParams
                ).toString();
                pageURL = `${pageURL}?${utmQueryString}`;
              }
            } catch (e) {
              console.error(e);
            }

            try {
              await withTimeout(
                formRegister({
                  formId: GOOGLE_REGISTER_FORMID,
                  array: hubspotFormData,
                  pageName: window.location.pathname,
                  pageURL,
                }),
                parseInt(process.env.DEFAULT_HUBSPOT_API_TIMEOUT) || 3000,
                { errorCode: "TIMEOUT" }
              );
            } catch (e) {
              console.error(e);
            }
          } else {
            AppAnalytics.track("signed.in", {
              email: response.data.email,
              user_id: response.data.user_id,
              data_account_id: response.data.customer_id,
              api_version: response.api_version,
              verified: true,
              formid: "dashboard google login",
              original_referrer_override: getUtmParams().referrer,
            });
          }
          loginToWorkspace(response.data);
          return response.data;
        }
      )
      .catch(err => {
        dispatch({ type: GOOGLE_LOGIN_FAIL });
        AppAnalytics.track("login.oauth.request.failure", {
          email: email,
          msg: JSON.stringify(err),
        });
        throw err;
      });
  };
};

const verifyGithubAuth = ({ code, currentRegion }, { apiError }) => {
  AppAnalytics.track("login.oauth.request.sent", { code });

  return api
    .service("dashboard", { raw: true })
    .post("/github-oauth/login", { code: code, currentRegion: currentRegion })
    .then(
      // eslint-disable-next-line complexity
      async response => {
        if (
          response.status === 400 ||
          response.status === 500 ||
          !response.data.success
        ) {
          AppAnalytics.track("login.oauth.request.failure", {
            code,
          });
          apiError(response.data.msg);
          return;
        }
        AppAnalytics.identify(response.data.customer_id, {
          email: response.data.email,
          first_name: response.data.first_name,
          last_name: response.data.last_name,
          user_id: response.data.user_id,
          data_account_id: response.data.customer_id,
          api_version: response.data.api_version,
          dashboard_version: process.env.REACT_APP_DASHBOARD_VERSION,
          events_protocol: process.env.REACT_APP_EVENTS_PROTOCOL,
          verified: true,
          original_referrer_override: getUtmParams().referrer,
          ...getUtmParams(),
        });
        AppAnalytics.track("login.oauth.request.success", {
          email: response.data.email,
          user_id: response.data.user_id,
          data_account_id: response.data.customer_id,
          api_version: response.api_version,
        });
        if (response.data.registered) {
          AppAnalytics.track("signed.up", {
            email: response.data.email,
            user_id: response.data.user_id,
            data_account_id: response.data.customer_id,
            api_version: response.api_version,
            verified: true,
            formid: "dashboard github signup",
            original_referrer_override: getUtmParams().referrer,
          });
          window.dataLayer.push({
            event: "signed.up",
            email: `${response.data.email}`,
          });
          // Get Unbounce data from query and pass to Hubspot
          const rawQuerys = new URLSearchParams(window.location.search);
          const queries = Object.fromEntries(rawQuerys.entries()) || {};

          hubspotFormData = [
            ["firstname", response.data.email.first_name],
            ["lastname", response.data.email.last_name],
            ["email", response.data.email],
            ["original_referrer_override", getUtmParams().referrer],
            ["contact_referrer", "register-github"],
            ["verified", true],
            ["phone", queries?.phone || ""],
            ["sdr_comments", queries?.sdr_comments || ""],
            ["company", queries?.company || ""],
          ];

          try {
            await withTimeout(
              formRegister({
                formId: GITHUB_REGISTER_FORMID,
                array: hubspotFormData,
                pageName: window.location.pathname,
                pageURL: window.location.href,
              }),
              parseInt(process.env.DEFAULT_HUBSPOT_API_TIMEOUT) || 3000,
              { errorCode: "TIMEOUT" }
            );
          } catch (e) {
            console.error(e);
          }
        } else {
          AppAnalytics.track("signed.in", {
            email: response.data.email,
            user_id: response.data.user_id,
            data_account_id: response.data.customer_id,
            api_version: response.api_version,
            verified: true,
            formid: "dashboard github login",
            original_referrer_override: getUtmParams().referrer,
          });
        }
        loginToWorkspace(response.data);
        return response.data;
      }
    )
    .catch(err => {
      AppAnalytics.track("login.oauth.request.failure", {
        code: code,
        msg: JSON.stringify(err),
      });
      throw err;
    });
};

const postLogout = () => {
  try {
    if (!isAdmin()) {
      api.service("dashboard").post(getAPIURL("logout"));
    }
  } catch (error) {
    console.error(error);
  }
  removeCurrentUser();
  history.push({ pathname: "/login", search: window?.location?.search });
  window.location.reload();
};

export {
  postRegister,
  postLogin,
  postForgetPwd,
  postResendEmail,
  verifyGoogleAuth,
  verifyGithubAuth,
  saveAndTrackLogin,
  redirectAfterLogin,
  postLogout,
};
