import { useEffect, useRef } from "react";
import { withRouter } from "react-router-dom";
import { AppAnalytics } from "./helpers/analytics_helper";

const capitalizeFirstLetter = string => {
  return string.charAt(0).toUpperCase() + string.slice(1);
};

const SegmentPageEvent = props => {
  let { history } = props;

  const page = useRef(history.location.pathname);

  useEffect(() => {
    const title = history.location.pathname.split("/")[1];
    const id =
      history.location.pathname.split("/").length > 1
        ? history.location.pathname.split("/")[2]
        : "";
    const path = history.location.pathname;

    AppAnalytics.track("page.viewed", {
      title,
      id,
      path,
      pathname: path,
      href: window.location.href,
      referrer: page.current,
    });

    AppAnalytics.page(capitalizeFirstLetter(title), {
      referrer: page.current,
      id,
    });

    page.current = history.location.pathname;
  }, [history.location.pathname, page]);

  return null;
};

export default withRouter(SegmentPageEvent);
