import React, { Component } from "react";
import { connect } from "react-redux";
import { Route, Switch } from "react-router-dom";
// Import Routes
import classNames from "classnames";
import { UAParser } from "ua-parser-js";
import { HMSThemeProvider, ThemeTypes } from "@100mslive/react-ui";
import logo from "./assets/images/hms-logo-header.svg";
import Downtime from "./components/Downtime";
import NonAuthLayout from "./components/NonAuthLayout";
// layouts
import VerticalLayout from "./components/VerticalLayout/";
import Plug from "./containers/Plug/Plug";
import AppRoute from "./routes/route";
import { MobileDeployment } from "./components";
import { authProtectedRoutes, publicRoutes } from "./routes";
import SegmentPageEvent from "./SegmentPageEvent";
import { baseTheme } from "./themeConfig";
import { currentUser } from "./utils";
// Import styles
import "./assets/scss/app-builder.scss";
import "./assets/scss/theme.scss";

class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
      desktopAgent: !UAParser()?.device?.type,
    };
    this.getLayout = this.getLayout.bind(this);
  }

  /**
   * Returns the layout
   */
  getLayout = () => VerticalLayout;
  render() {
    const Layout = this.getLayout();

    const authUser = currentUser();
    localStorage.removeItem("reg_user");
    return (
      <React.Fragment>
        <HMSThemeProvider
          themeType={ThemeTypes.dark}
          theme={{ colors: { ...baseTheme.colors } }}
        >
          <Plug currentUser={authUser} />
          <SegmentPageEvent />
          <div
            className={classNames("mobile:hidden", {
              hidden: this.state.desktopAgent,
            })}
          >
            <div className="bg-surface-default">
              <img src={logo} alt="100ms" className="h-10 m-4" />
            </div>
            <div className="flex justify-center h-screen items-center bg-black">
              <MobileDeployment
                link={`https://www.100ms.live${window.location.search}`}
              />
            </div>
          </div>
          <div
            className={classNames({
              "hidden mobile:block": !this.state.desktopAgent,
            })}
          >
            <Switch>
              {process.env.REACT_APP_DOWNTIME === "true" ? (
                <Route path="/">
                  <Downtime />
                </Route>
              ) : null}

              {publicRoutes.map((route, idx) => (
                <AppRoute
                  path={route.path}
                  layout={NonAuthLayout}
                  component={route.component}
                  key={idx}
                  isAuthProtected={false}
                  isFullScreen={route.fullscreen || false}
                />
              ))}
              {authProtectedRoutes.map((route, idx) => (
                <AppRoute
                  path={route.path}
                  layout={Layout}
                  component={route.component}
                  key={idx}
                  isAuthProtected={true}
                  isFullScreen={route.fullscreen || false}
                />
              ))}
            </Switch>
          </div>
        </HMSThemeProvider>
      </React.Fragment>
    );
  }
}

const mapStateToProps = state => {
  return {
    layout: state.Layout,
  };
};
export default connect(mapStateToProps, null)(App);
