import {
  currentUser,
  getCurrentWorkspaceID,
  getUtmParams,
  isAdmin,
} from "../utils";

export const analytics_store = {
  data: { workspaceOwnerEmail: null },
  set: payload => {
    for (const index in payload) {
      analytics_store.data[index] = payload[index];
    }
  },
  get: () => analytics_store?.data,
};

const getCommonOptions = () => ({
  dashboard_version: process.env.REACT_APP_DASHBOARD_VERSION,
  events_protocol: process.env.REACT_APP_EVENTS_PROTOCOL,
  timestamp: new Date().toString(),
  platform: "hms-dashboard",
  ...getUtmParams(),
});

const analyticsTrack = (title, options) => {
  try {
    const user = currentUser();
    const workspaceID = getCurrentWorkspaceID();
    if (!user) {
      window.analytics.track(title, {
        ...getCommonOptions(),
        ...options,
      });
    } else if (user && !isAdmin()) {
      window.analytics.track(title, {
        email: user.email,
        customer_id: user.customer_id,
        workspaceID,
        workspaceOwnerEmail: analytics_store.get()?.workspaceOwnerEmail,
        api_version: user.api_version,
        ...getCommonOptions(),
        ...options,
      });
    }
  } catch {}
};

const analyticsPage = (title, options) => {
  const user = currentUser();
  if (!user) {
    window.analytics.page(title, {
      ...getCommonOptions(),
      ...options,
    });
  } else if (user && !isAdmin()) {
    window.analytics.page(title, {
      email: user.email,
      customer_id: user.customer_id,
      api_version: user.api_version,
      ...getCommonOptions(),
      ...options,
    });
  }
};

const analyticsIdentify = (id, options) => {
  const user = currentUser();
  if (!user || (user && !isAdmin())) {
    window.analytics.identify(id, {
      ...getCommonOptions(),
      ...options,
    });
  }
};

export const AppAnalytics = {
  identify: analyticsIdentify,
  track: analyticsTrack,
  page: analyticsPage,
};

export const analyticsCoachmarksDone = () => {
  AppAnalytics.track("coachmark.done.clicked", {
    page: window.location.pathname,
  });
};
